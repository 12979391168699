// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-campaign-details-jsx": () => import("./../../../src/templates/campaignDetails.jsx" /* webpackChunkName: "component---src-templates-campaign-details-jsx" */),
  "component---src-templates-child-campaign-details-jsx": () => import("./../../../src/templates/childCampaignDetails.jsx" /* webpackChunkName: "component---src-templates-child-campaign-details-jsx" */),
  "component---src-templates-digital-daana-campaign-details-jsx": () => import("./../../../src/templates/digitalDaanaCampaignDetails.jsx" /* webpackChunkName: "component---src-templates-digital-daana-campaign-details-jsx" */),
  "component---src-templates-digital-daana-child-campaign-jsx": () => import("./../../../src/templates/digitalDaanaChildCampaign.jsx" /* webpackChunkName: "component---src-templates-digital-daana-child-campaign-jsx" */),
  "component---src-templates-event-detail-jsx": () => import("./../../../src/templates/eventDetail.jsx" /* webpackChunkName: "component---src-templates-event-detail-jsx" */),
  "component---src-templates-news-details-jsx": () => import("./../../../src/templates/newsDetails.jsx" /* webpackChunkName: "component---src-templates-news-details-jsx" */),
  "component---src-templates-ngo-details-jsx": () => import("./../../../src/templates/ngoDetails.jsx" /* webpackChunkName: "component---src-templates-ngo-details-jsx" */)
}

